.form-background {
  height: auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  margin: 30px;
  padding: 20px;
  gap: 10px;
}
.inner-form {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.first-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: block;
  max-height: 200px;
  width: 200px;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-left {
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: flex-start;
}

.loading-label {
  font-size: 12px;
  color: rgb(34, 3, 3);
}
.error-message {
  color: rgb(233, 18, 18);
}

.design-pref-label {
  width: 280px;
}

.upload-button {
  margin-right: -50px;
}

.system-input-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.system-input {
  width: 100%;
  margin-bottom: 10px;
}
.closed-won-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.input-margin {
  margin-top: 10px;
}
.input-width {
  width: 100%;
}
